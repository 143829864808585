<template>
  <v-container class="bg-section py-16" fluid>
    <v-row>
      <v-col :md="3" class="">
      </v-col>
      <v-col cols="11" :lg="6" class="text-center">
        <h1 class="mb-6">Cursos y certificaciones</h1>
        <p class="">
          Con Acist tienes la oportunidad de llevar los cursos que quieras
          al ritmo que prefieras. Obtén tu Certificado Virtual.
        </p>
      </v-col>
      <v-col :md="3" class="hidden-md-and-down">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BannerResources",

  data: () => ({}),
};
</script>

<style scoped>
.bg-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}
</style>
