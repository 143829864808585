<template>
  <div class="p-5">
    <article class="card card--1">
      <div class="card__img my-img" :style="`background-image: url('`+image+`');`"></div>
      <a
        :href="link"
        target="_blank"
        class="card_link"
      >
        <div class="card__img--hover my-img" :style="`background-image: url('`+image+`');`"></div>
      </a>
      <div class="card__info">
        <h3 class="card__title">
          {{title}}
        </h3>
        <span class="card__by">
          <i class="fas fa-map-marker-alt mr-1"></i>
          {{mood}}</span
        >
      </div>
    </article>
  </div>
</template>

<script>
export default {
    name: 'myCard',
    props: ['title', 'mood', 'image', 'link', 'description']
  }
</script>
<style scoped>
.my-card {
  border-radius: 20px;
}
.my-img {
  border-radius: 16px;
  border: solid 10px rgb(255, 255, 255);
}
.content-center {
  text-align: -webkit-center;
}

.card__img {
  visibility: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 235px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.card__info-hover {
  position: absolute;
  padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
  color: #fff;
}

.card__img--hover {
  transition: 0.2s all ease-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  height: 235px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  top: 0;
}
.card {
  margin-right: 25px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
  width: 100%;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
}
.card:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
  transform: scale(1.1, 1.1);
}

.card__info {
  z-index: 2;
  background-color: #fff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 16px 24px 24px 24px;
}

.card__title {
  margin-bottom: 10px;
  font-size: 19px;
}
.card__by {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.card__author {
  font-weight: 600;
  text-decoration: none;
}

.card:hover .card__img--hover {
  height: 100%;
  opacity: 0.25;
}

.card:hover .card__info {
  background-color: transparent;
  color: #fff;
  position: relative;
}

.card:hover .card__info-hover {
  color: #fff;
  opacity: 1;
}
</style>
