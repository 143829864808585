<template>
  <div>
    <div class="container-cards mt-16 mx-1 md:mx-10 mb-20">
      <div
        class="px-1 md:px-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2lg:grid-cols-4"
      >
        <v-row >
          <v-col cols="12" :md="3"  v-for="a in courses" :key="a">
            <div>
              <myCard
                :title="a.title"
                :mood="a.mood"
                :image="a.image"
                :link="a.link"
                :description="a.description"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import myCard from "./elements/myCard.vue";
export default {
  name: "Courses",

  components: {
    myCard,
  },
  data: () => ({
    courses: [
      {
        title: "Diseño Gráfico Digital",
        description: "Expresar tus ideas a través de imágenes...",
        mood: "Curso en Modalidad Virtual",
        price: "100",
        link: "",
        image:
          "http://www.acisteduca.com/images/curso-virtual-de-diseno-grafico-digital.jpg",
      },
      {
        title: "Cursos de Ofimática",
        description: "Conviértete en un experto en Office Empresarial...",
        mood: "Curso en Modalidad Virtual",
        price: "100",
        link: "",
        image:
          "http://www.acisteduca.com/images/curso_distancia_de-ofimatica-2019.jpg",
      },
      {
        title: "Certificación de Ofimática",
        description: "Obtén rápido certificado de Office...",
        mood: "Certificado con examen de suficiencia",
        price: "100",
        link: "",
        image:
          "http://www.acisteduca.com/images/obtener-certificado-de-ofimatica.jpg",
      },
      {
        title: "Certificación de Inglés",
        description: "Obtén rápido certificado de Inglés...",
        mood: "Certificado con examen de suficiencia",
        price: "100",
        link: "",
        image:
          "http://www.acisteduca.com/images/certificado-de-ingles-basico-intermedio-avanzado.jpg",
      },
    ],
  }),
};
</script>

<style>
.cont-bg {
  height: 89vh;
  position: relative;
}
.img-bg {
  z-index: 3;
  width: 50%;
  position: absolute;
}
.img-abstract {
  width: 7%;
  position: absolute;
  margin-left: 40%;
  z-index: 4;
}
.container-cards {
  background-color: #e7e7e7;
  border-radius: 30px;
  min-height: 500px;
}
.my-card {
  border-radius: 20px;
}
.my-img {
  border-radius: 16px;
  border: solid 10px rgb(255, 255, 255);
}
.content-center {
  text-align: -webkit-center;
}
</style>
