<template>
  <v-container class="pa-0" fluid>
    <BannerResources />
    <Courses />
  </v-container>
</template>

<script>
  import BannerResources from '../components/sections/BannerResources.vue'
  import Courses from '../components/sections/Courses.vue'

  export default {
    name: 'Resources',

    components: {
      BannerResources,
      Courses
    },
  }
</script>